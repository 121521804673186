import React from 'react';
import { makeStyles } from '@mui/styles';
import { ReactComponent as Logo2 } from '../resources/images/logo2.svg';

const useStyles = makeStyles(() => ({
  image: {
    alignSelf: 'center',
    maxWidth: '240px',
    maxHeight: '120px',
    width: 'auto',
    height: 'auto',
  },
}));

const LogoImage2 = ({ color }) => {
  const classes = useStyles();

  return (<Logo2 className={classes.image} style={{ color }} />);
};

export default LogoImage2;
